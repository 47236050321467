@import "../color";

.start-date-icon {
  width: 20px;
  height: 20px;
  background-image: url(../../assets/img/icon/icons8-date-to-24.png);
}

.low-card {
  border-left: 2px solid $green;
}

.normal-card {
  border-left: 2px solid $blue;
}

.high-card {
  border-left: 2px solid $yellow;
}

.critical-card {
  border-left: 2px solid $red;
}

.work-time-tag {
  span {
    background-color: #acacac;
  }
}

.low-tag {
  span {
    background-color: $green;
  }
}

.normal-tag {
  span {
    background-color: $blue;
  }
}

.high-tag {
  span {
    background-color: $yellow;
  }
}

.critical-tag {
  span {
    background-color: $red;
  }
}
