.ng-select {
  .ng-select-container {
    display: flex;
    align-items: center;
    min-height: 40px;
    border: 1px solid #d1d5db;
    border-radius: 6px;
    padding-inline-start: 10px;

    > * {
      border-top: 0 !important;
    }

    .ng-value-container {
      padding: .4em 0;

      .ng-value {
        margin: 0 0.4375em 0.4em 0;
      }
    }
  }

  .ng-select-container::after {
    border-bottom: 0;
  }
}

.ng-dropdown-panel {
  .ng-dropdown-panel-items {
    .ng-option {
      line-height: 2rem;
      min-height: 2rem;
    }
  }
}
