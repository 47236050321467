.e-gantt {
  .highlight-title {
    color: rgb(252, 41, 41);
  }

  .e-gantt-chart {
    //.e-baseline-bar {
    //  background-color: #5BA4C6FF !important;
    //  height: 6px !important;
    //}
    //
    .e-gantt-parent-taskbar-inner-div {
      height: 12px !important;
      margin-top: 3px !important;
    }

    .e-gantt-child-taskbar-inner-div {
      height: 12px !important;
      margin-top: 3px !important;
    }

    //
    //.e-gantt-child-progressbar-inner-div{
    //  background: #1937c7 !important;
    //  outline: 1px solid #1937c7 !important;
    //}
  }

  .e-indicator-span {
    z-index: 4;
  }

  .e-notes-info::before {
    color: #878787 !important
  }
}

.e-grid {
  .e-gridheader {
    tr:first-child {
      th {
        padding: 0 0.5rem !important;
      }
    }
  }

}

.e-dialog {
  max-width: 740px !important;

  .e-edit-form-row {
    height: 350px !important;
  }
}

.e-pager {
  .e-currentitem {
    border: 4px solid #053B50 !important;
  }
}



