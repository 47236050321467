.dialog {
  max-width: 35vw !important;
  max-height: 50vh !important;
}

.p-dialog {
  .p-dialog-header {
    padding: 1.25rem;
  }
}
