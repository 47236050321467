/* You can add global styles to this file, and also import other style files */

/* https://github.com/SyncfusionExamples/ej2-angular-sscss/blob/master/src/styles.sscss */
@import '../node_modules/@syncfusion/ej2-material-theme/styles/material.css';
@import "@ng-select/ng-select/themes/material.theme.css";
@import './scss/ej2';
@import "./scss/select";
@import "./scss/color";
@import "./scss/primeng/kanban-card";
@import "./scss/primeng/dialog";



body, html {
  margin: 0;
  padding: 0;
  background: #FAFAFA;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: "Noto Sans TC", "Roboto", "Helvetica Neue", sans-serif;

  * {
    box-sizing: border-box;
  }
}

a {
  text-decoration: none;
}

.data-box {
  background: #ffffff;
  color: #495057;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  padding: 1rem;
}

.data-box:hover {
  background: #FCFCFC;
  cursor: pointer;
}

.common-height {
  height: calc(100vh - 64px);
}

.sidebar-invisible {
  width: calc(100% - 5rem);
  transition: all .5s ease;
}

.sidebar-visible {
  width: calc(100% - 12.5rem);
  transition: all .5s ease;
}

.sidebar-close-width {
  width: 4.5rem;
  transition: all .5s ease;
}

.sidebar-show-width {
  width: 12rem;
  transition: all .5s ease;
}

.sidebar-open-right-card-open {
  width: calc(100% - 52rem);
  transition: all .5s ease;
}

.sidebar-close-right-card-open {
  width: calc(100% - 44.5rem);
  transition: all .5s ease;
}

.sidebar-close-right-card-close {
  width: calc(100% - 5rem);
  transition: all .5s ease;
}
